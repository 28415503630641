@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --negro: #000000;
    --blanco: #ffffff;
    --gris-oscuro:  #3d3c3b;
    --gris-semi-oscuro:  #666666;
    --gris-semi-claro:  #cccccc;
    --gris-claro:  #e4e4e4;
    --red-del: #d6073b;
}

* {
    font-family: "Poppins", Arial, Helvetica, sans-serif;
    padding: 0px;
    margin: 0px;
    transition: all 0.5s ease;
}

.btn {
    cursor: pointer;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    margin: 0 5px;
}
.btn-verde {
    background-color: #064e06;
    color: var(--blanco);
}
.btn-azul {
    background-color: #00719e;
    color: var(--blanco);
}
.btn-rojo {
    background-color: var(--red-del);
    color: var(--blanco);
}

.cont-app {
    display: flex;
    justify-content: space-between;
    width: 100vw;
    height: 100vh;
}
/*** BARRA LATERAL **/
.cont-barra-lateral {
    background-color: var(--gris-oscuro);
    width: 300px;
    height: 100%;
}
.cont-section-barra {
    background-color: var(--gris-semi-oscuro);
    margin: 10px;
    border-radius: 10px;
    padding: 10px;
    width: calc(100% - 40px);
}
.titulo-administrador {
    font-size: 18px;
    text-align: center;
    color: var(--blanco);
}
.bl-nombre-admin {
    color: var(--blanco);
    font-size: 12px;
    margin-top: 10px;
    text-align: center;
}
.cont-item-bl {
    margin: 10px;
}
.bl-divisor-title {
    font-size: 14px;
    border-bottom: var(--gris-semi-oscuro) solid 1px;
    color: var(--blanco);
}
.bl-categoria-button {
    display: block;
    width: 100%;
    text-align: center;
    padding: 10px 0;
    border: var(--gris-claro) solid 1px;
    border-radius: 10px;
    margin: 5px 0;
    cursor: pointer;
    background-color: var(--gris-oscuro);
    color: var(--blanco);
}
/*** CONT-OUTLET **/
.cont-section {
    width: calc(100% -  300px);
    background-color: var(--gris-claro);
    height: 100%;
    overflow-y: auto;
}
.cont-lista-productos-tabla {
    width: calc(100% - 40px);
    padding: 0px 20px 20px 20px;
}
/*** TABLA **/
.tabla-productos {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid;
    text-overflow: ellipsis;
}
thead {
    background-color: var(--gris-oscuro);
    color: var(--blanco);
}
tbody {
    background-color: var(--gris-semi-claro);
    color: var(--negro);
}
thead path {
    fill: var(--blanco);
}
th, td {
    font-size: 0.8rem;
    padding: 0px 10px;
    text-align: left;
    border: 1px solid #ddd;
}
th {
    padding: 10px 10px;
    text-align: center;
    position: sticky;
    top: 88px;
    z-index: 1;
    background-color: var(--gris-oscuro);
}
tr:nth-child(even) {background-color: #f2f2f2;}
tbody th {
    font-weight: 500;
    font-size: 11px;
}
.td-image {
    width: 50px;
}
.td-image img {
    width: 100%;
}
.td-acciones {
  
}
.td-acciones button {
}
.header-productos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    position: sticky;
    top: 0px;
    background-color: var(--gris-claro);
    z-index: 10;
    padding-top: 20px;
}
.cont-header-prod-buttons {
    display: flex;
    gap: 10px;
}
/*** AGREGAR MODIFICAR **/
.form-product-title-section {
    margin: 10px 0;
    font-size: 20px;
    border-bottom: var(--gris-oscuro) solid 2px;
    padding-bottom: 5px;
}
.cont-checks-box-items {
    max-width: 70%;
}
.cont-checkboxs-subcategorias {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
}
.cont-checkbox-tipo {
    display: flex;
    align-items: center;
    gap: 5px;
    flex-direction: row-reverse;
}
#form-agregar-modificar {
}
.form-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--gris-semi-claro);
    border-radius: 15px;
    padding: 10px 20px;
    margin: 10px 0;
}
.cont-form-item {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.cont-form-item span {
    font-size: 11px;
    color: #d6073b;
}
.cont-form-item input, .cont-form-item textarea {
    padding: 10px;
    width: calc(100% - 20px);
    border-radius: 10px;
    border: var(--gris-oscuro) solid 1px;
}
.label-form-group-unit {
    font-weight: 600;
    font-size: 15px;
}
.cont-form-item-row-1 {
    width: 100%;
}
.cont-form-item-row-2 {
    width: 48%;
}
.cont-form-item-row-3 {
    width: 32%;
}
.form-images-producto {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.container-imagenes-producto {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
}
.img-prodcuto-form {
    border-radius: 10px;
    width: calc(((100vw - 380px) / 5) - 10px);
    height: calc(((100vw - 380px) / 5) - 10px);
    background-size: cover;
    background-position: center;
    position: relative;
}
.cont-img-form-prod-icon {
    position: absolute;
    z-index: 9;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 10px;
}
.icon-home {
    bottom: 0;
    left: 0;
    background-color: var(--negro);
}
.icon-home-active {
    background-color: #c7ba00 !important;
}
.icon-del {
    top: 0;
    right: 0;
    background-color: var(--red-del);
}
.btn-salir-adm {
    background-color: var(--red-del);
    color: white;
    border-radius: 10px;
    padding: 5px 10px;
    margin-top: 10px;
    border: none;
    width: 100%;
    cursor: pointer;
}
